
import { getYxDetail } from './api'
import { getList } from './../thirdQradeReport/api'
export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/threeQualityManagement/thirdQradeReceiving",
        name: "thirdQradeReceiving",
        title: "终包收货提报列表"
      },
      {
        path: "/threeQualityManagement/thirdQradeReceivingDetail",
        name: "thirdQradeReceivingDetail",
        title: "终包收货提报详情"
      }
      ],
      pageLoadFlag: false,
      detilInfo: {
      },
      columns: [
        {

          title: "物料编码",
          dataIndex: "code",

        },
        {
          title: "物料名称",
          dataIndex: "name",

        },
        {
          title: "发货数量",
          dataIndex: "deliveryQuantity",

        },
        {
          title: "收货数量",
          dataIndex: "finallyNum",

          scopedSlots: { customRender: 'finallyNum' },
        },

      ],
      createdDateStart: '',
      tableData: [

      ],

    };
  },


  mounted() {
    if (this.$route.query.taskId) {
      this.getDetailInfo(this.$route.query.taskId)
    } else {
      this.$message.warning('获取详情失败')
    }

  },

  methods: {
    // 获取当前时间
    fun_date() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      return yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
    },
    changeNum(row) {
    },

    // 获取详情
    getDetailInfo(aid) {
      let data = { taskId: aid }
      getList(data).then(res => {
        if (res.data.msg == '操作成功') {
          this.detilInfo = res.data.data[0]
          this.tableData = res.data.data[0].detailList
          this.tableData.forEach(item => {
            item.finallyNum = item.receiveQuantity
          })
        } else {
          this.$message.warning(res.data.msg)
        }

      })
    },
    goback() {
      window.close()
      this.$router.push({ path: '/threeQualityManagement/thirdQradeReport' });
    },
    // 提交
    sumbit(status) {
      // status 1：提交，0：保存
      // id	string	是	任务id
      // userName	string	是	收货人员ldap账号
      // receiveDate	string	是	收货时间 yyyy-MM-dd HH:mm:ss
      // detailList	array	是	明细列表
      // detailList.id	string	是	明细id
      // detailList.quantity	decimal	是	明细提报数量
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].finallyNum > this.tableData[i].deliveryQuantity) {
          this.$message.warning('收货数量不能大于发货数量')
          return false
        }
      }
      let detailList = []
      this.tableData.forEach(item => {
        detailList.push({
          id: item.id,
          quantity: item.finallyNum,
        })
      })
      let data = {
        status: status,
        id: this.$route.query.taskId,
        userName: this.$store.state.user.userInfo.account.account,
        receiveDate: this.fun_date(),
        detailList: detailList
      }
      this.pageLoadFlag = true
      getYxDetail(data).then(res => {
        if (res.data.msg == '操作成功') {
          this.$message.success(res.data.msg)
          this.pageLoadFlag = false
          window.opener.location.reload();
          this.timer = setTimeout(() => {  //设置延迟执行
            this.goback()
          }, 2000);
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false
        }
      })
    }
  }
};